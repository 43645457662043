import React from 'react';
// import logo from './logo.svg';
import './App.css';
import MainWrapper from './components/layout/MainWrapper'

const App: React.FC = () => {
  return (
    <MainWrapper />
  );
}

export default App;
