import React, { useState } from 'react';
import { Table, Paper, TableHead, TableRow, TableCell, TableBody, Link } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import * as queries from '../graphql/queries';
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import HisStatus from '../components/his-status/HisStatus'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

function createMarkup(htmlContent: string) {
  return { __html: htmlContent };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    link: {
      margin: theme.spacing(1),
    },
  }),
);

function createData(name: string, branch: string, tag: string, commit: string, url: string, pipeline: string) {
  return { name, branch, tag, commit, url, pipeline };
}

const rows = [
  // createData('HIS-DEV', 'dev', '-', '123qwe', 'https://dev.his.clinivantage.dev/', '<a href="http://ec2-34-217-13-229.us-west-2.compute.amazonaws.com/clinivantage/his/commits/dev" target="_blank"><img alt="pipeline status" src="http://ec2-34-217-13-229.us-west-2.compute.amazonaws.com/clinivantage/his/badges/dev/pipeline.svg" /></a>'),
  createData('HIS-INTDEV', 'intdev', '-', '123qwe', 'https://intdev.his.clinivantage.dev/', '<a href="http://ec2-34-217-13-229.us-west-2.compute.amazonaws.com/clinivantage/his/commits/intdev" target="_blank"><img alt="pipeline status" src="http://ec2-34-217-13-229.us-west-2.compute.amazonaws.com/clinivantage/his/badges/intdev/pipeline.svg" /></a>'),
  createData('HIS-QA', 'qa', '-', '123qwe', 'https://qa.his.clinivantage.dev/', '<a href="http://ec2-34-217-13-229.us-west-2.compute.amazonaws.com/clinivantage/his/commits/qa" target="_blank"><img alt="pipeline status" src="http://ec2-34-217-13-229.us-west-2.compute.amazonaws.com/clinivantage/his/badges/qa/pipeline.svg" /></a>'),
//   createData('HIS-STAGING', 'staging', '-', '123qwe', 'https://staging.his.clinivantage.dev/', '<a href="http://ec2-34-217-13-229.us-west-2.compute.amazonaws.com/clinivantage/his/commits/staging" target="_blank"><img alt="pipeline status" src="http://ec2-34-217-13-229.us-west-2.compute.amazonaws.com/clinivantage/his/badges/staging/pipeline.svg" /></a>')
];

const HisHome: React.FC = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Branch</TableCell>
            <TableCell>Tag</TableCell>
            <TableCell>Commit</TableCell>
            <TableCell>URL</TableCell>
            <TableCell>Health</TableCell>
            <TableCell>Pipeline</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.branch}</TableCell>
              <TableCell>{row.tag}</TableCell>
              {/* <TableCell>{row.commit}</TableCell> */}
              <TableCell><HisStatus branch={row.branch} /></TableCell>
              <TableCell>
                <Link href={row.url} target="_blank" rel="noopener" className={classes.link}>
                  {row.url}&nbsp;<OpenInNewIcon />
                </Link>
              </TableCell>
             <TableCell><RadioButtonCheckedIcon htmlColor="green"/></TableCell>
              <TableCell><div dangerouslySetInnerHTML={createMarkup(row.pipeline)} /></TableCell>
              {/* <TableCell><div>WIP</div></TableCell> */}
            </TableRow>
          ))}
        </TableBody>
        {/* 
        <TableBody>
          <Connect query={graphqlOperation(queries.listProjests)}>
            // @ts-ignore
            {({ data: { listProjests }, loading, errors }) => {
              if (errors) return (<h3>Error</h3>);
              if (loading || !listProjests) return (<h3>Loading...</h3>);
              return (
                <>
                  {listProjests.items.map((item: any) => (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="row">
                        {item.env}
                      </TableCell>
                      <TableCell>{item.branch}</TableCell>
                      <TableCell>{item.tag}</TableCell>
                      <TableCell>{item.commitId}</TableCell>
                      <TableCell>{item.envUrl}</TableCell>
                    </TableRow>
                  ))}
                </>

              );
            }}
          </Connect>
        </TableBody>
         */}
      </Table>
    </Paper >
  )
}

export default HisHome