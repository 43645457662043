import React, { Suspense, useState } from 'react';
import useFetch from '@rohanray/fetch-suspense';

const FetchResponse: React.FC<{ branch: string }> = ({ branch }) => {
  const { contentType, response, status, bodyUsed, headers } = useFetch(`https://${branch}.his.clinivantage.dev/healthcheck.json`, { method: 'GET' }, { metadata: true });
  // const { contentType, response, status, bodyUsed, headers } = useFetch('http://worldclockapi.com/api/json/utc/now', { method: 'GET' }, { metadata: true });
  console.log("The server responded with", contentType, response, status, bodyUsed, headers)
  let responseString = JSON.stringify(response)

  let commitId = responseString.split("\"commit\":")[1].substring(1,7)

  return (
    <p>
      {commitId}
    </p>
    // <div>
    //   {`The server responded with ${contentType}: ${JSON.stringify(response)} : ${status} : ${bodyUsed} : ${JSON.stringify(headers)}`}
    // </div>
  )
}

const HisStatus: React.FC<{ branch: string }> = ({ branch }) => {
  const [isDevNginx, updateIsDevNginx] = useState(false)

  return (
    <Suspense fallback="...">
      <FetchResponse branch={branch} />
    </Suspense>
  )
}

export default HisStatus